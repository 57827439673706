import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { FullWidthLoaderComponent } from './full-width-loader/full-width-loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [ErrorDisplayComponent, FullWidthLoaderComponent, ConfirmationDialogComponent],
  exports: [
    ErrorDisplayComponent,
    FullWidthLoaderComponent,
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule
    ]
})
export class SharedModule { }
