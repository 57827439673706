import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private _router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const hasLoggedUser = !!localStorage.getItem('userJwt');
        if (!hasLoggedUser) {
            this._router.navigate(['/login']);
        }
        return hasLoggedUser;
    }
}
