<div class="page">
  <div  class="error-message" *ngIf="errorMessage">
    <p>
      {{errorMessage}}
    </p>
  </div>
  <div class="container">
    <div class="left">
      <div class="login-logo" style="text-align: center;"><img src="../../assets/logo/ttg-logo.svg"></div>
      <div class="eula"><h2>Admin Portal</h2></div>
    </div>
    <div class="right">
      <svg viewBox="0 0 320 300">
        <defs>
          <linearGradient
                          inkscape:collect="always"
                          id="linearGradient"
                          x1="130"
                          y1="193.49992"
                          x2="307"
                          y2="193.49992"
                          gradientUnits="userSpaceOnUse">
            <stop
                  style="stop-color:#f33a21;"
                  offset="0"
                  id="stop876" />
            <stop
                  style="stop-color:#f33a21;"
                  offset="1"
                  id="stop878" />
          </linearGradient>
        </defs>
        <path d="m 40,120.00016 239.99984,-3.2e-4 c 0,0 24.99263,0.79932 25.00016,35.00016 0.008,34.20084 -25.00016,35 -25.00016,35 h -239.99984 c 0,-0.0205 -25,4.01348 -25,38.5 0,34.48652 25,38.5 25,38.5 h 215 c 0,0 20,-0.99604 20,-25 0,-24.00396 -20,-25 -20,-25 h -190 c 0,0 -20,1.71033 -20,25 0,24.00396 20,25 20,25 h 168.57143" />
      </svg>
      <form class="form"  [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <!-- email input form -->
        <label for="email">Email</label>
        <input type="email" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" >
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
        </div>

        <!-- password input form -->
        <label for="password">Password</label>
        <input type="password"  formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
        <input type="submit" id="submit" value="Login">
      </form>
    </div>
  </div>
</div>

