import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {AuthenticationComponent} from '../authentication/authentication.component';
import {AuthenticationGuard} from '../authentication/authentication.guard';
import {RootComponent} from './root.component';
import {RootRoutesModules} from './root.routes';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpHeadersInterceptor} from '../http-headers.interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from '../../shared/shared.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ClientService} from '../client/services/client.service';


@NgModule({
    declarations: [
        RootComponent,
        AuthenticationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RootRoutesModules,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        SharedModule,
        MatSnackBarModule,
        MatDialogModule,
        FlexLayoutModule,
        MatSnackBarModule
    ],
    providers: [
        AuthenticationGuard,
        {provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}
    ],

    bootstrap: [RootComponent]

})

export class RootModule {
}
