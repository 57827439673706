import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class HttpHeadersInterceptor implements HttpInterceptor {

    constructor(private _router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let jwt = localStorage.getItem('userJwt');
        let newHeaders = new HttpHeaders();
        if (jwt) {
            newHeaders = req.headers.set('Authorization', jwt);
        }

        if (!newHeaders.has('Content-Type')) {
            newHeaders = newHeaders.append('Content-Type', 'application/json');
        } else if (newHeaders.get('Content-Type') === 'file-upload') {
            newHeaders = newHeaders.delete('Content-Type');
        }

        if (!newHeaders.has('Accept')) {
            newHeaders = newHeaders.append('Accept', 'application/json');
        }

        const authReq = req.clone({
            headers: newHeaders
        });

        return next.handle(authReq).pipe(tap(() => {
            },
            (error: any) => {
                if (error && error.status === 401) {
                    localStorage.removeItem(`userJwt`);
                    this._router.navigateByUrl('/login');
                    return;
                }
            })
        );
    }

}
