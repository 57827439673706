import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {user} from '../../user/user.model';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})

export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<user>;
    public currentUser: Observable<user>;

    constructor(private _http: HttpClient, private _router: Router) {
        this.currentUserSubject = new BehaviorSubject<user>(
            JSON.parse(localStorage.getItem('currentUser'))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): user {
        return this.currentUserSubject.value;
    }

    login(user: string, pass: string): Observable<any> {
        return this._http.post<any>(`${environment.apiBaseUrl}/login`, {'userName': user, 'password': pass});
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('userJwt');
        localStorage.removeItem('dealers');
        localStorage.removeItem('features');
        localStorage.removeItem('clientId');
        this._router.navigate(['/login']);
        // this.currentUserSubject.next(null);
    }


}
