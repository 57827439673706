import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from './service/authentication.service';
import * as CryptoJS from 'crypto-js';
import jwt_decode from 'jwt-decode';
import * as crypto from 'crypto-js';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;
    loading = false;
    returnUrl: string;
    errorMessage: string = null;

    constructor(
        private _formBuilder: FormBuilder,
        private _authenticationService: AuthenticationService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {

        if (this._authenticationService.currentUserValue) {
            this._router.navigate(['/app']);
        }
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

    }

    get f(): any {
        return this.loginForm.controls;
    }


    onSubmit(): void {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this._authenticationService.login(this.f.username.value, crypto.SHA1(this.f.password.value).toString()).subscribe(
            data => {
                const userLoginInfo: any = jwt_decode(data.token);
                console.log(userLoginInfo.role);
                if (userLoginInfo.role !== 'Super Admin' && userLoginInfo.role !== 'Admin') {
                    this.errorMessage = 'Invalid user type';
                } else {
                    if (data.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('userJwt', data.token);
                        console.log('Local Storage: ' + localStorage.getItem('userJwt'));

                    }

                    console.log('Loggado:' + this.f.username.value);
                    this._router.navigate(['/app/client']);
                }
            },
            error => {
                this.errorMessage = error.error.message;
                console.log(this.errorMessage);
                this.loading = false;
            }
        );
    }



}
