import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthenticationComponent } from "../authentication/authentication.component";
import { AuthenticationGuard } from "../authentication/authentication.guard";


@NgModule({
  imports:[
    RouterModule.forRoot([

      {path: `login`, component: AuthenticationComponent},
      {path: `app`, canActivate: [AuthenticationGuard],  loadChildren: () => import('../app.module').then(m => m.AppModule)},
      {path: '', redirectTo: '/app', pathMatch: 'full'}
    ])
  ],

  exports:[
    RouterModule
  ]
})

export class RootRoutesModules{}
